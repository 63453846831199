import React from "react"
import styled from "@emotion/styled"
import LogoWhite from "../images/logo-white.svg"
import Instagram from "../images/instagram.svg"
import Linkedin from "../images/linkedin.svg"
import * as vars from "../styles/vars"

const Footer = () => {
  return (
    <FooterContainer id="contacto">
      <div className="container">
        <div className="info">
          <img src={LogoWhite} alt="Ainoa Molina" />
          <p>¡Gracias por visitar mi web portfolio!</p>
          <p>
            Si te gusta lo que hago, tienes alguna propuesta o algo que decirme,
            ¡escríbeme!
          </p>
          <p>
            <a href="mailto:toctoc@heyainoa.com">toctoc@heyainoa.com</a>
          </p>
        </div>
        <div className="social">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/hey_ainoa/"
          >
            <img src={Instagram} alt="instagram" />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/ainoa-molina-molina/"
          >
            <img src={Linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
      <div className="container legal">
        <div className="copy">
          <p>© Copyright {new Date().getFullYear()} | All rights reserved.</p>
        </div>
        <div className="love">
          <p>❤ DESIGNED WITH LOVE, SIEMPRE</p>
        </div>
      </div>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer`
  background: ${vars.green};
  padding: 50px 0 25px;
  ${vars.sm} {
    padding: 50px 0;
  }
  ${vars.md} {
    padding: 75px 0 50px;
  }
  ${vars.lg} {
    padding: 70px 0 35px;
  }
  ${vars.xl} {
    padding: 100px 0 50px;
  }
  ${vars.xxl} {
    padding: 150px 0 75px;
  }
  .container {
    display: block;
    ${vars.md} {
      align-items: flex-start;
      display: flex;
    }
  }
  .info {
    flex: 0 0 40%;
    img {
      margin-bottom: 20px;
      ${vars.md} {
        margin-bottom: 27px;
      }
      ${vars.xl} {
        margin-bottom: 17px;
      }
      ${vars.xxl} {
        margin-bottom: 20px;
      }
    }
    p {
      font-family: ${vars.barlow};
      font-size: 0.938rem;
      line-height: 18px;
      font-weight: 300;
      margin-bottom: 20px;
      ${vars.hoverColor}
      ${vars.sm} {
        font-size: 1.25rem;
        line-height: 24px;
        margin-bottom: 25px;
      }
      ${vars.md} {
        font-size: 0.938rem;
        line-height: 18px;
        margin-bottom: 20px;
      }
      ${vars.lg} {
        font-size: 1.125rem;
        line-height: 22px;
        margin-bottom: 35px;
      }
      ${vars.xl} {
        font-size: 1.563rem;
        line-height: 30px;
        margin-bottom: 28px;
      }
      ${vars.xxl} {
        font-size: 1.875rem;
        line-height: 36px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-transform: none;
        font-weight: 700;
        cursor: pointer;
        background-image: -webkit-gradient(
          linear,
          left bottom,
          left top,
          from(white),
          color-stop(50%, white),
          color-stop(50%, ${vars.black})
        );
        background-image: linear-gradient(
          0deg,
          white,
          white 50%,
          ${vars.black} 50%
        );
      }
    }
  }
  .social {
    a {
      margin-bottom: 20px;

      ${vars.md} {
        margin-bottom: 10px;
        display: block;
      }
      ${vars.lg} {
        margin-bottom: 14px;
      }
      ${vars.xl} {
        margin-bottom: 17px;
      }
      ${vars.xxl} {
        margin-bottom: 20px;
      }
      &:first-of-type {
        margin-top: 51px;
      }
      img {
        width: 25px;
        margin-right: 10px;
        margin-top: 20px;
        height: auto;
        ${vars.sm} {
          width: 30px;
          margin-right: 20px;
          margin-top: 25px;
        }
        ${vars.md} {
          width: 24px;
          margin: 0;
        }
        ${vars.lg} {
          width: 30px;
        }
        ${vars.xl} {
          width: 39px;
        }
        ${vars.xxl} {
          width: 50px;
        }
      }
    }
  }
  .legal {
    font-family: ${vars.barlow};
    font-size: 0.938rem;
    line-height: 18px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .copy {
      order: 1;
      flex: 0 0 100%;
      ${vars.md} {
        order: 0;
        flex: 0 0 50%;
      }
    }
    .love {
      order: 0;
      margin-bottom: 10px;
      flex: 0 0 100%;
      text-align: left;
      ${vars.sm} {
        margin-bottom: 25px;
      }
      ${vars.md} {
        text-align: right;
        order: 1;
        flex: 0 0 50%;
        margin-bottom: 0;
      }
    }
    .love p {
      color: white;
    }
    ${vars.sm} {
      font-size: 1.25rem;
      line-height: 24px;
      padding-top: 25px;
    }
    ${vars.md} {
      font-size: 0.938rem;
      line-height: 18px;
      padding-top: 25px;
    }
    ${vars.lg} {
      font-size: 1.125rem;
      line-height: 22px;
      padding-top: 35px;
    }
    ${vars.xl} {
      font-size: 1.563rem;
      line-height: 30px;
      padding-top: 30px;
    }
    ${vars.xxl} {
      font-size: 1.875rem;
      line-height: 36px;
      padding-top: 75px;
    }
  }
`
