// Queries
export const sm = '@media (min-width: 576px)'
export const mobile = '@media (max-width: 768px)'
export const md = '@media (min-width: 768px)'
export const lg = '@media (min-width: 992px)'
export const xl = '@media (min-width: 1200px)'
export const xxl = '@media (min-width: 1596px)'

// Fuentes
export const barlow = 'Barlow, sans-serif'

//colores
export const green = '#2CFFB9'
export const black = '#080808'

export const hoverColor = `
a{
    color: ${black};
    text-transform: uppercase;
    color: ${black};
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-image: -webkit-gradient(linear, left bottom, left top, from(${green}), color-stop(50%, ${green}), color-stop(50%, ${black}));
    background-image: linear-gradient(0deg, ${green}, ${green} 50%, ${black} 50%);
    background-size: 100% 200%;
    background-position: 0% 0%;
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.23, 1);
    transition: all 0.3s cubic-bezier(0, 0, 0.23, 1);
    &:hover{
        -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.23, 1);
        transition: all 0.3s cubic-bezier(0, 0, 0.23, 1);
        background-position: 100% 100%;
    }
}
&.current{
    a{
        -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.23, 1);
        transition: all 0.3s cubic-bezier(0, 0, 0.23, 1);
        background-position: 100% 100%;
    }
    
}
`