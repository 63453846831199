/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

import HeaderContainer from "./header"
import { Global, css } from "@emotion/core"
import * as vars from "../styles/vars"
import Footer from "./footer"
import Cursor from "./cursor"

const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            cursor: none;
          }
          body {
            overflow-x: hidden;
          }
          body.hide {
            overflow: hidden;
          }
          a {
            text-decoration: none;
          }
          .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            position: relative;
            flex-wrap: wrap;
            width: 100%;
            max-width: calc(100% - 60px);

            ${vars.md} {
              max-width: 690px;
            }
            ${vars.lg} {
              max-width: 930px;
            }
            ${vars.xl} {
              max-width: 1110px;
            }
            ${vars.xxl} {
              max-width: 1506px;
            }
          }
          h1 {
            font-family: ${vars.barlow};
            font-size: 1.875rem;
            line-height: 36px;
            color: ${vars.black};
            font-weight: 800;
            ${vars.sm} {
              font-size: 2.625rem;
              line-height: 50px;
            }
            ${vars.md} {
              font-size: 2.188rem;
              line-height: 42px;
            }
            ${vars.lg} {
              font-size: 2.813rem;
              line-height: 54px;
            }
            ${vars.xl} {
              font-size: 3.438rem;
              line-height: 66px;
            }
            ${vars.xxl} {
              font-size: 4.063rem;
              line-height: 78px;
            }
            span {
              color: ${vars.green};
            }
          }
          p {
            font-family: ${vars.barlow};
            font-weight: 300;
            color: ${vars.black};
            font-size: 0.938rem;
            line-height: 18px;
            ${vars.sm} {
              font-size: 1.25rem;
              line-height: 24px;
            }
            ${vars.md} {
              font-size: 0.938rem;
              line-height: 18px;
            }
            ${vars.lg} {
              font-size: 1.25rem;
              line-height: 24px;
            }
            ${vars.xl} {
              font-size: 1.563rem;
              line-height: 30px;
            }
            ${vars.xxl} {
              font-size: 1.875rem;
              line-height: 36px;
            }
          }
        `}
      />
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Barlow:300,700,800&display=swap"
        />
      </Helmet>
      <HeaderContainer />
      <main>{children}</main>
      <Footer />
      <Cursor />
    </>
  )
}

export default Layout
