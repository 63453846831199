import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import classNames from "classnames"
import * as vars from "../styles/vars"

const CursorContainer = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  transition: all 150ms ease;
  transition-property: opacity, background-color, transform;
  &.cursor {
    &&--hidden {
      opacity: 0;
    }
    &&--link-hovered {
      transform: translate(-50%, -50%) scale(3);
      border: 1px solid #000;
    }
    &&--clicked {
      transform: translate(-50%, -50%) scale(2);
    }
  }
`

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [hidden, setHidden] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [linkHovered, setLinkHovered] = useState(false)

  useEffect(() => {
    addEventListeners()
    handleLinkHoverEvents()
    return () => removeEventListeners()
  }, [])

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove)
    document.addEventListener("mouseenter", onMouseEnter)
    document.addEventListener("mouseleave", onMouseLeave)
    document.addEventListener("mousedown", onMouseDown)
    document.addEventListener("mouseup", onMouseUp)
  }

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseenter", onMouseEnter)
    document.removeEventListener("mouseleave", onMouseLeave)
    document.removeEventListener("mousedown", onMouseDown)
    document.removeEventListener("mouseup", onMouseUp)
  }

  const onMouseMove = e => {
    setPosition({ x: e.pageX, y: e.pageY })
  }
  const onMouseLeave = () => {
    setHidden(true)
  }

  const onMouseEnter = () => {
    setHidden(false)
  }

  const onMouseDown = () => {
    setClicked(true)
  }

  const onMouseUp = () => {
    setClicked(false)
  }

  const handleLinkHoverEvents = () => {
    document.querySelectorAll("a").forEach(el => {
      el.addEventListener("mouseover", () => setLinkHovered(true))
      el.addEventListener("mouseout", () => setLinkHovered(false))
    })
  }

  const cursorClasses = classNames("cursor", {
    "cursor--clicked": clicked,
    "cursor--hidden": hidden,
    "cursor--link-hovered": linkHovered,
  })

  const isMobile = () => {
    const ua = navigator.userAgent
    return /Android|Mobi/i.test(ua)
  }
  if (typeof navigator !== "undefined" && isMobile()) return null

  return (
    <CursorContainer
      className={cursorClasses}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    />
  )
}

export default Cursor
