import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "@emotion/styled"
import logoGreen from "../images/logo-green.svg"
import * as vars from "../styles/vars"
import { gsap } from "gsap"
import React from "react"
import WindowSizeListener from "react-window-size-listener"

const HeaderContainer = () => {
  const navToggle = e => {
    if (window.innerWidth < 768) {
      if (!e.target.classList.contains("active")) {
        e.target.classList.add("active")
        e.target.innerHTML = "X"
        gsap.to("nav ul", 0.35, { left: 0 })
        document.body.classList.add("hide")
      } else {
        e.target.classList.remove("active")
        e.target.innerHTML = "MENÚ"
        gsap.to("nav ul", 0.35, { left: "100%" })
        document.body.classList.remove("hide")
      }
    }
  }
  const linkClick = e => {
    if (window.innerWidth < 768) {
      document.body.classList.remove("hide")
      gsap.to("nav ul", 0.35, { left: "100%" })
      document.querySelector(".menu nav span").classList.remove("active")
      document.querySelector(".menu nav span").innerHTML = "MENÚ"
    }
  }

  return (
    <HeaderSection>
      <WindowSizeListener
        onResize={windowSize => {
          if (windowSize.windowWidth > 768) {
            gsap.to("nav ul", 0, { left: 0 })
            document.body.classList.remove("hide")
            document.querySelector(".menu nav span").classList.remove("active")
            document.querySelector(".menu nav span").innerHTML = "MENÚ"
          } else {
            document.body.classList.remove("hide")
            gsap.to("nav ul", 0.35, { left: "100%" })
            document.querySelector(".menu nav span").classList.remove("active")
            document.querySelector(".menu nav span").innerHTML = "MENÚ"
          }
        }}
      />
      <div className="container">
        <div className="logo">
          <AniLink
            cover
            bg="#2CFFB9"
            onTouchEnd={linkClick}
            onClick={linkClick}
            to="/"
          >
            <img src={logoGreen} alt="Ainoa Molina" />
          </AniLink>
        </div>

        <Menu className="menu">
          <nav>
            <span onClick={navToggle} onKeyDown={navToggle}>
              menú
            </span>
            <ul>
              <li>
                <AniLink
                  cover
                  bg="#2CFFB9"
                  onTouchEnd={linkClick}
                  onClick={linkClick}
                  to="/sobre-mi"
                  activeClassName="current"
                >
                  SOBRE MÍ
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  bg="#2CFFB9"
                  onTouchEnd={linkClick}
                  onClick={linkClick}
                  to="/proyectos"
                  activeClassName="current"
                >
                  PROYECTOS
                </AniLink>
              </li>
              <li>
                <a onClick={linkClick} onTouchEnd={linkClick} href="#contacto">
                  CONTACTO
                </a>
              </li>
            </ul>
          </nav>
        </Menu>
      </div>
    </HeaderSection>
  )
}

export default HeaderContainer

const HeaderSection = styled.header`
  padding: 24px 0;
  background: white;
  position: fixed;
  width: 100%;
  z-index: 9;
  ${vars.md} {
    padding: 10px 0;
  }
  ${vars.lg} {
    padding: 20px 0;
  }
  ${vars.xxl} {
    padding: 22px 0;
  }
  .logo {
    position: relative;
    z-index: 10;
    cursor: pointer;
    img {
      width: 35px;
      height: auto;
    }
  }
`
const Menu = styled.div`
  nav {
    span {
      font-family: ${vars.barlow};
      color: ${vars.black};
      font-weight: 300;
      font-size: 1.125rem;
      line-height: 22px;
      position: relative;
      text-transform: uppercase;
      z-index: 10;
      cursor: pointer;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${vars.green};
        position: absolute;
        bottom: -8px;
        left: 0;
      }
      ${vars.md} {
        display: none;
      }
    }
    ul {
      ${vars.mobile} {
        display: flex;
        position: fixed;
        z-index: 9;
        flex-direction: column;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 30px;
        opacity: 1;
        left: 100%;
      }
      ${vars.md} {
        display: flex;
      }
      list-style: none;
      justify-content: flex-end;
      color: ${vars.black};
      position: relative;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${vars.green};
        position: absolute;
        bottom: -28px;
        ${vars.md} {
          bottom: -16px;
        }
        ${vars.lg} {
          bottom: -25px;
        }
        ${vars.xxl} {
          bottom: -28px;
        }
      }
      li {
        ${vars.mobile} {
          margin-left: 0;
        }
        margin-left: 40px;
        &:first-of-type {
          margin-left: 0;
        }
        a {
          font-family: ${vars.barlow};
          font-weight: 700;
          text-transform: uppercase;
          font-size: 2.813rem;
          line-height: 54px;
          cursor: pointer;
          ${vars.sm} {
            font-size: 3.75rem;
            line-height: 96px;
          }
          ${vars.md} {
            font-size: 0.938rem;
            line-height: 18px;
            font-weight: 300;
          }
          ${vars.lg} {
            font-size: 1.125rem;
            line-height: 22px;
          }
          ${vars.xxl} {
            font-size: 1.25rem;
            line-height: 24px;
          }
          &.current {
            color: ${vars.green};
            background: none;
            -webkit-text-fill-color: ${vars.green};
          }
        }

        ${vars.hoverColor}
      }
    }
  }
`
